@import url('https://fonts.googleapis.com/css2?family=League+Gothic&display=swap');

html {
    font-size: 10px;
    background-color: #f2f2f2;
}

h1,h2 {
    font-family: 'League Gothic', sans-serif;
    letter-spacing: .2rem;
}

h1 {
    font-size: 4rem;
}

h2 {
    font-size: 3rem;
}

a {
    font-size: 1.4rem;
    text-decoration: none;
}